import allianz from '../../../../../img/insurance/productores/marcas/allianz.jpg'
import asociart from '../../../../../img/insurance/productores/marcas/Asociart_b.jpg'
import berkley from '../../../../../img/insurance/productores/marcas/berkley.png'
import chubb from '../../../../../img/insurance/productores/marcas/chubb.jpg'
import cruz from '../../../../../img/insurance/productores/marcas/cruz.jpg'
import experta from '../../../../../img/insurance/productores/marcas/experta.jpg'
import galeno from '../../../../../img/insurance/productores/marcas/galeno.png'
import integity from '../../../../../img/insurance/productores/marcas/integrity.jpg'
import mapfre from '../../../../../img/insurance/productores/marcas/mapfre.png'
import meridional from '../../../../../img/insurance/productores/marcas/meridional.jpg'
import omint from '../../../../../img/insurance/productores/marcas/omint.png'
import orbis from '../../../../../img/insurance/productores/marcas/orbis.jpg'
import prevencionArt from '../../../../../img/insurance/productores/marcas/prevencion-art.jpg'
import provincia from '../../../../../img/insurance/productores/marcas/provincia.jpg'
import sanCristobal from '../../../../../img/insurance/productores/marcas/san-cristobal_b.jpg'
import sancor from '../../../../../img/insurance/productores/marcas/sancor.jpg'
import galicia from '../../../../../img/insurance/productores/marcas/galicia_seguros.jpg'
import swissMedical from '../../../../../img/insurance/productores/marcas/swiss-medical.png'
import nowAssistance from '../../../../../img/insurance/productores/marcas/now-assistance_b.jpg'
import zurich from '../../../../../img/insurance/productores/marcas/zurich.jpg'


const info = [
    {
        id: '1',
        nombre: 'allianz',
        img: allianz,
    },
    {
        id: '2',
        nombre: 'asociart',
        img: asociart,
    },
    {
        id: '3',
        nombre: 'chubb',
        img: chubb,
    },
    {
        id: '4',
        nombre: 'cruz',
        img: cruz,
    },
    {
        id: '5',
        nombre: 'experta',
        img: experta,
    },
    {
        id: '6',
        nombre: 'galeno',
        img: galeno,
    },
    {
        id: '7',
        nombre: 'integrity',
        img: integity,
    },
    {
        id: '8',
        nombre: 'mapfre',
        img: mapfre,
    },
    {
        id: '9',
        nombre: 'meridional',
        img: meridional,
    },
    {
        id: '10',
        nombre: 'omint',
        img: omint,
    },
    {
        id: '11',
        nombre: 'orbis',
        img: orbis,
    },
    {
        id: '12',
        nombre: 'Prevencion Art',
        img: prevencionArt,
    },
    {
        id: '13',
        nombre: 'provincia',
        img: provincia,
    },
    {
        id: '14',
        nombre: 'San Cristobal',
        img: sanCristobal,
    },
    {
        id: '15',
        nombre: 'sancor',
        img: sancor,
    },
    {
        id: '16',
        nombre: 'galicia',
        img: galicia,
    },
    {
        id: '17',
        nombre: 'Swiss Medical',
        img: swissMedical,
    },
    {
        id: '18',
        nombre: 'now',
        img: nowAssistance,
    },
    {
        id: '19',
        nombre: 'zurich',
        img: zurich,
    },
    {
        id: '20',
        nombre: 'berkley',
        img: berkley,
    },



]

export const getEmpresaInfo = () => {
    return new Promise((resolve) => {
        resolve(info)
    })
}